<template>
    <p class="montserrat font-bold text-5xl pt-20 mb-4 text-center">The future of healthy and diverse workspaces is now.</p>
    <p class="text-center text-2xl mb-12">We gave employers and employees the insights and tools to make the best of it.</p>
    <p class="text-2xl mb-12 mt-40 text-center">We are Digital Three</p>
    <div class="sm:flex justify-center">
        <div class="mb-12">
            <img class="w-3/4 mx-auto mb-4 xl:w-1/2" src="./assets/avatars/avatar-hilbert.png" alt="Avatar Hilbert">
            <p class="montserrat text-xl text-center">Hilbert Simonse</p>
            <div class="mt-4 text-center">
                <a href="https://simonsedesign.nl/">
                    <font-awesome-icon class="fa-2x mx-2 filter-drop-shadow" :icon="['fal', 'globe']" />
                </a>
                <a href="https://linkedin.com/in/hilbert-simonse/">
                    <font-awesome-icon class="fa-2x mx-2 filter-drop-shadow" :icon="['fab', 'linkedin-in']" />
                </a>
            </div>
        </div>
        <div class="mb-12">
            <img class="w-3/4 mx-auto mb-4 xl:w-1/2" src="./assets/avatars/avatar-ibo.png" alt="Avatar Ibo">
            <p class="montserrat text-xl text-center">Ibo van Geffen</p>
            <div class="mt-4 text-center">
                <a href="https://ibovangeffen.com">
                    <font-awesome-icon class="fa-2x mx-2 filter-drop-shadow" :icon="['fal', 'globe']" />
                </a>
                <a href="https://linkedin.com/in/ibovangeffen">
                    <font-awesome-icon class="fa-2x mx-2 filter-drop-shadow" :icon="['fab', 'linkedin-in']" />
                </a>
            </div>
        </div>
        <div class="mb-12">
            <img class="w-3/4 mx-auto mb-4 xl:w-1/2" src="./assets/avatars/avatar-floor.png" alt="Avatar Floor">
            <p class="montserrat text-xl text-center">Floor Vermaat</p>
            <div class="mt-4 text-center">
                <a href="https://floorvermaat.com/">
                    <font-awesome-icon class="fa-2x mx-2 filter-drop-shadow" :icon="['fal', 'globe']" />
                </a>
                <a href="https://linkedin.com/in/floor-vermaat/">
                    <font-awesome-icon class="fa-2x mx-2 filter-drop-shadow" :icon="['fab', 'linkedin-in']" />
                </a>
            </div>
        </div>
    </div>
    <div class="mb-20 pl-4 pr-2 sm:pl-8 sm:pr-4 md:pl-12 md:pr-8 lg:pl-20 lg:pr-16 xl:pl-28 xl:pr-24">
        <p class="text-center">The team consists of people from various backgrounds. Hilbert is a student Communication & Multimedia Design, whereas Floor and Ibo are students Computer Sciences.</p>
    </div>
    <div class="flex flex-wrap lg:flex-nowrap mb-20">
        <div class="bg-orange text-white py-8 w-full sm:inline-block sm:w-1/2 lg:w-1/3">
            <font-awesome-icon class="fa-6x block mx-auto mt-6 mb-12 filter-drop-shadow" :icon="['fal', 'question']" />
            <div class="pl-4 pr-2">
                <h2 class="montserrat uppercase text-2xl font-bold mb-4 border-b-4 border-white inline-block pr-2">Why</h2>
                <p class="mb-4">Digital Three got tasked with improving the YourDesq platform, on behalf of YourDesq and Strakzat. The current YourDesq platform consists of a booking platform, where workplaces and offices can be offered, searched for and booked.</p>
                <p>As it seemed the platform mostly attracted freelancers and students. YourDesq wanted growth and attract bigger companies onto its platform, so they tasked Digital Three with the challenge to find a solution to the problem in a way that matched the platforms state and userbase.</p>
            </div>
        </div>
        <div class="bg-white py-8 w-full sm:inline-block sm:w-1/2 lg:w-1/3">
            <font-awesome-icon class="fa-6x block mx-auto mt-6 mb-12 filter-drop-shadow" :icon="['fal', 'drafting-compass']" />
            <div class="pl-4 pr-2">
                <h2 class="montserrat uppercase text-2xl font-bold mb-4 border-b-4 border-dark-blue inline-block pr-2">How</h2>
                <p class="mb-4">The project had multiple phases. We started with the discovery phase, in which we looked at the intentions, insights, and the implementation. The team had interviews and evaluated what really mattered.</p>
                <p>This was followed by the ideation phase, in which we sketched out ideas and came up with three different concepts and low fidelity prototypes. After choosing one concept, we continued to the realization phase.</p>
            </div>
        </div>
        <div class="bg-dark-blue py-8 text-white w-full lg:inline-block lg:w-1/3">
            <font-awesome-icon class="fa-6x block mx-auto mt-6 mb-12 filter-drop-shadow" :icon="['fal', 'tools']" />
            <div class="pl-4 pr-2">
                <h2 class="montserrat uppercase text-2xl font-bold mb-4 border-b-4 border-white inline-block pr-2">What</h2>
                <p>All the phases and products of the first phases led to one final result, a high fidelity prototype. The prototype has been evaluated and improved in multiple rounds, with potential new users giving their continued feedback to the platform as it was at that stage.</p>
            </div>
        </div>
    </div>
    <p class="text-xl mb-4 text-center">Together we created</p>
    <Title class="text-center"></Title>

    <div class="md:flex">
        <div class="md:w-1/2 mb-4 md:mb-0">
            <img class="mx-auto" src="./assets/mockups/imac-yourdesq-dashboard.png" alt="YourDesq Dashboard"/>
        </div>
        <div class="md:w-1/2 xl:w-1/3 md:self-center">
            <p class="pl-4 pr-2 mb-6">A full featured platform built for both employers and employees so it manages the productivity and wellbeing of the employees.</p>

            <p class="montserrat uppercase font-semibold pl-4 pr-2">Improved wellbeing</p>
            <p class="pl-4 pr-2 mb-6">Our platform helps employers to gain insights in the productivity and wellbeing of the employees. It motivates employees to submit data to the platform by having an opportunity to save for free courses.</p>

            <p class="montserrat uppercase font-semibold pl-4 pr-2">Improved knowledge</p>
            <p class="pl-4 pr-2">The platform allows both employers and employees to buy courses to follow online and also have a training session at one of the many YourDesq locations.</p>
        </div>
    </div>

    <div class="bg-orange text-white mb-4 mt-12 md:mb-8 md:mt-16 xl:mb-12 xl:mt-20">
        <div class="montserrat text-center font-semibold uppercase text-2xl py-8 xl:py-12 filter-drop-shadow">Research</div>
    </div>
    <div class="">
        <div class="md:self-center">
            <p class="pl-4 pr-2 mb-4 md:w-1/2 xl:w-1/3 mx-auto">To understand how employers experienced the ‘new way of working’ we interviewed employers and did a lot of desk research. This gave us some interesting insights we didn’t have yet.</p>
            <div class="pl-4 pr-2 w-full flex flex-wrap">
                <div class="w-full sm:w-1/2 md:w-1/3">
                    <img src="./assets/research/research-card-1.png" alt="Research feedback" class="w-full sm:w-60 lg:w-80 mx-auto my-8">
                </div>
                <div class="w-full sm:w-1/2 md:w-1/3">
                    <img src="./assets/research/research-card-2.png" alt="Research feedback" class="w-full sm:w-60 lg:w-80 mx-auto my-8">
                </div>
                <div class="w-full sm:w-1/2 md:w-1/3">
                    <img src="./assets/research/research-card-3.png" alt="Research feedback" class="w-full sm:w-60 lg:w-80 mx-auto my-8">
                </div>
                <div class="w-full sm:w-1/2 md:w-1/3">
                    <img src="./assets/research/research-card-4.png" alt="Research feedback" class="w-full sm:w-60 lg:w-80 mx-auto my-8">
                </div>
                <div class="w-full sm:w-1/2 md:w-1/3">
                    <img src="./assets/research/research-card-5.png" alt="Research feedback" class="w-full sm:w-60 lg:w-80 mx-auto my-8">
                </div>
                <div class="w-full sm:w-1/2 md:w-1/3">
                    <img src="./assets/research/research-card-6.png" alt="Research feedback" class="w-full sm:w-60 lg:w-80 mx-auto my-8">
                </div>
            </div>
        </div>
    </div>
    <div class="bg-dark-blue mb-4 mt-12 md:mb-8 md:mt-16 xl:mb-12 xl:mt-20">
        <div class="montserrat text-center font-semibold uppercase text-white text-2xl py-8 xl:py-12 filter-drop-shadow">Concepting</div>
    </div>
    <div class="flex flex-wrap lg:flex-nowrap">
        <div class="py-8 w-full sm:inline-block sm:w-1/2 lg:w-1/3">
            <img class="lg:w-1/2 mx-auto mb-8" src="./assets/mockups/iphone-social-booking.png" alt="Social booking system concept" />
            <div class="pl-4 pr-2">
                <p class="montserrat uppercase font-semibold pl-4 pr-2">Social booking system</p>
                <p class="pl-4 pr-2 mb-6">To create more connections and be more social in a hard time like this, we came up with this concept to book a workspace where more people from the same branch work and you could talk and connect with them.</p>
            </div>
        </div>
        <div class="py-8 w-full sm:inline-block sm:w-1/2 lg:w-1/3">
            <img class="lg:w-1/2 mx-auto mb-8" src="./assets/mockups/iphone-dashboard.png" alt="Dashboard concept" />
            <div class="pl-4 pr-2">
                <p class="montserrat uppercase font-bold pl-4 pr-2">Dashboard</p>
                <p class="pl-4 pr-2 mb-6">Our idea was to showcase the amount of money saved by booking workspaces and to show their productivity compared to other places. This would encourage the employers to get all their employees on YourDesq.</p>
            </div>
        </div>
        <div class="py-8 w-full lg:inline-block lg:w-1/3 sm:flex">
            <img class="sm:w-1/2 mx-auto mb-8" src="./assets/mockups/iphone-inspiration-platform.png" alt="Hybrid inspiration platform concept" />
            <div class="w-full sm:w-1/2 lg:w-full pl-4 pr-2 self-center">
                <p class="montserrat uppercase font-semibold pl-4 pr-2">Hybrid inspiration platform</p>
                <p class="pl-4 pr-2 mb-6">To switch into the ‘new way of working’ we came up with the inspiration platform, which in where experts would post articles and where employees and employers could post questions and get answers back from experts to help get into the ‘new way of working’.</p>
            </div>
        </div>
    </div>
    <div class="w-full">
        <div class="mb-20 pl-4 pr-2 lg:w-1/3 lg:mx-1/3 lg:mx-auto">
            <p class="montserrat uppercase font-semibold pl-4 pr-2">The picked concept</p>
            <p class="pl-4 pr-2 mb-6">Our supervising teacher, Digital Three, Strakzat and YourDesq all choose for the dashboard concept, which at first was just a regular dashboard with all kinds of statistics, but we changed that to a productivity and wellbeing platform. This is because wellbeing is so important in these times. This concept was the one thing employers and employees really needed.</p>
        </div>
    </div>
    <div class="bg-orange text-white mb-4 mt-12 md:mb-8 md:mt-16 xl:mb-12 xl:mt-20">
        <div class="montserrat text-center font-semibold uppercase text-2xl py-8 xl:py-12 filter-drop-shadow">Sketches & wireframes</div>
    </div>
    <div class="sm:flex">
        <div class="w-full sm:w-1/2 lg:w-1/3 pl-4 pr-2 mx-auto">
            <p class="montserrat uppercase font-semibold pl-4 pr-2">Sketches</p>
            <p class="pl-4 pr-2 mb-6">To sketch our ideas, we followed the core design sprint method called Crazy 8’s. It’s a fast sketching technique to create 8 refreshing ideas where you get one minute to sketch out each idea. The goal was to push ourselves to come up with refreshing ideas to build the dashboard concept.</p>
        </div>
        <div class="w-full sm:w-1/2 lg:w-1/3 mb-20 sm:mb-0 pl-4 pr-2 mx-auto">
            <p class="montserrat uppercase font-semibold pl-4 pr-2">Wireframes</p>
            <p class="pl-4 pr-2 mb-6">After the sketching we decided which ideas were best and converted those sketches into wireframes. These are the blueprints for our design. During the wireframing we had to think about the user interaction, flow and the logic. </p>
        </div>
    </div>

    <div class="bg-dark-blue text-white mb-4 mt-12 md:mb-8 md:mt-16 xl:mb-12 xl:mt-20">
        <div class="montserrat text-center font-semibold uppercase text-2xl py-8 xl:py-12 filter-drop-shadow">Styleguide</div>
    </div>
    <div class="pl-4 pr-2">
        <div class="w-full pl-4 pr-2">
            <p class="mb-6 text-center">The styleguide was already present, as YourDesq already uses it on their own website.</p>
        </div>
        <div class="lg:flex">
            <div class="w-full lg:w-1/2 pl-4 pr-2 mb-6">
                <div class="w-full">
                    <p class="montserrat uppercase font-semibold mb-4">Typography</p>
                </div>
                <div class="sm:flex mb-4">
                    <div class="w-full sm:w-1/2">
                        <p class="circular-std-black text-2xl">Circular STD Black</p>
                        <p class="circular-std-black uppercase">abcdefghijklmnopqrstuvwxyz</p>
                        <p class="circular-std-black lowercase">abcdefghijklmnopqrstuvwxyz</p>
                    </div>
                    <div class="w-full sm:w-1/2 self-center">
                        <p class="font-bold">Big headings (H1 / H2)</p>
                    </div>
                </div>
                <div class="sm:flex mb-4">
                    <div class="w-full sm:w-1/2">
                        <p class="circular-std-bold text-2xl">Circular STD Bold</p>
                        <p class="circular-std-bold uppercase">abcdefghijklmnopqrstuvwxyz</p>
                        <p class="circular-std-bold lowercase">abcdefghijklmnopqrstuvwxyz</p>
                    </div>
                    <div class="w-full sm:w-1/2 self-center">
                        <p class="font-bold">Small headings (H3 / H4 / H5 / H6 / Titles / Tags)</p>
                    </div>
                </div>
                <div class="sm:flex mb-4">
                    <div class="w-full sm:w-1/2">
                        <p class="circular-std-medium text-2xl">Circular STD Medium</p>
                        <p class="circular-std-medium uppercase">abcdefghijklmnopqrstuvwxyz</p>
                        <p class="circular-std-medium lowercase">abcdefghijklmnopqrstuvwxyz</p>
                    </div>
                    <div class="w-full sm:w-1/2 self-center">
                        <p class="font-bold">Important body text (Navigation)</p>
                    </div>
                </div>
                <div class="sm:flex mb-4">
                    <div class="w-full sm:w-1/2">
                        <p class="circular-std-book text-2xl">Circular STD Book</p>
                        <p class="circular-std-book uppercase">abcdefghijklmnopqrstuvwxyz</p>
                        <p class="circular-std-book lowercase">abcdefghijklmnopqrstuvwxyz</p>
                    </div>
                    <div class="w-full sm:w-1/2 self-center">
                        <p class="font-bold">Body text (Subtitle / Body)</p>
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-1/2 pl-4 pr-2 mb-20 sm:mb-0">
                <p class="montserrat uppercase font-semibold mb-4">Colors</p>
                <div class="sm:flex">
                    <div class="flex mb-4 sm:w-1/3">
                        <div class="w-1/2 sm:w-1/3">
                            <div class="bg-orange h-12 w-12 rounded-lg filter-drop-shadow"></div>
                        </div>
                        <div class="w-1/2 sm:w-1/3 self-center">
                            <p>#F5885E</p>
                        </div>
                    </div>
                    <div class="flex mb-4 sm:w-1/3">
                        <div class="w-1/2 sm:w-1/3">
                            <div class="bg-dark-blue h-12 w-12 rounded-lg filter-drop-shadow"></div>
                        </div>
                        <div class="w-1/2 sm:w-1/3 self-center">
                            <p>#111A49</p>
                        </div>
                    </div>
                    <div class="flex mb-4 sm:w-1/3">
                        <div class="w-1/2 sm:w-1/3">
                            <div class="bg-white h-12 w-12 rounded-lg filter-drop-shadow"></div>
                        </div>
                        <div class="w-1/2 sm:w-1/3 self-center">
                            <p>#FFFFFF</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-orange text-white mb-4 mt-12 md:mb-8 md:mt-16 xl:mb-12 xl:mt-20">
        <div class="montserrat text-center font-semibold uppercase text-2xl py-8 xl:py-12 filter-drop-shadow">Design iterations & testing</div>
    </div>
    <div class="pl-4 pr-2">
        <div class="sm:flex">
            <div class="w-full md:w-1/2">
                <p class="pl-4 pr-2 mb-6">Our first iteration was getting the basics right. The basic pages and our minimum viable product that we wanted to create as the productivity and wellbeing dashboard.</p>
                <p class="pl-4 pr-2 mb-6">The second iteration was about creating the entire experience. Create e-mails that would let a new employee join the company’s page on YourDesq. Another flow we started working on there was the one of the NPS-survey.</p>
            </div>
            <div class="w-full md:w-1/2">
                <p class="pl-4 pr-2 mb-6">And lastly, the third iteration was finalizing the product with all the feedback we had gotten from our testers and supervisors and we added the courses and trainings to the product. This brought us to the final product.</p>
                <p class="pl-4 pr-2 mb-6">As an example we've worked out the dashboard in several iterations. After every iteration we had, with a total of three, we tested it with our target group, the employers and employees. After receiving the feedback, we evaluated suggestions and tested the designs again.</p>
            </div>
        </div>
        <div class="sm:flex">
            <div class="w-full md:w-1/2 px-20">
                <img class="pl-4 pr-2 mb-6 filter-drop-shadow " src="./assets/iterations/dashboard-first.png" alt="First dashboard iteration" />
                <img class="pl-4 pr-2 mb-6 filter-drop-shadow" src="./assets/iterations/dashboard-second.png" alt="Second dashboard iteration" />
            </div>
            <div class="w-full md:w-1/2 px-20">
                <img class="pl-4 pr-2 mb-6 filter-drop-shadow" src="./assets/iterations/dashboard-third.png" alt="Third dashboard iteration" />
                <img class="pl-4 pr-2 mb-6 filter-drop-shadow" src="./assets/iterations/dashboard-final.png" alt="Final dashboard iteration" />
            </div>
        </div>
        <div class="w-full mt-8">
            <p class="pl-4 pr-2 mb-6 text-center">To learn more about our process of iterating, feel free to download the PDF below.</p>

            <div class="mx-auto block text-center">
                <a class="bg-orange rounded-md text-white montserrat uppercase px-4 py-2 hover:filter-drop-shadow" href="/testing/design-iterations-testing.pdf" download="">Download (NL)</a>
            </div>
        </div>
    </div>

    <div class="bg-dark-blue text-white mb-4 mt-12 md:mb-8 md:mt-16 xl:mb-12 xl:mt-20">
        <div class="montserrat text-center font-semibold uppercase text-2xl py-8 xl:py-12 filter-drop-shadow">Case video</div>
    </div>
    <div class="pl-4 pr-2">
        <div class="w-full sm:w-1/2 lg:w-1/3 mx-auto">
            <div class="video-container">
                <iframe src="https://www.youtube.com/embed/bJxggFjxrvQ" title="YouTube video player" frameborder="0" allow="encrypted-media; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>

    <div class="bg-orange text-white mb-4 mt-12 md:mb-8 md:mt-16 xl:mb-12 xl:mt-20">
        <div class="montserrat text-center font-semibold uppercase text-2xl py-8 xl:py-12 filter-drop-shadow">Result</div>
    </div>
    <div class="pl-4 pr-2">
        <div class="flex">
            <p class="pl-4 pr-2 mb-6 sm:w-1/2 mx-auto">After all the iterations we had, we finally came to a point where we were satisfied with our final prototype. It took many hours, a lot of testing and discussions before we came to the end, but we are happy with the result. Here is some of our favorite screens below.</p>
        </div>
        <div class="sm:flex">
<!--            <img class="w-full sm:w-1/2 drop-shadow-2xl" style="transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg);" src="./assets/results/dashboard.png" alt="Dashboard" />-->
            <p class="sm:hidden sm:w-1/2 pl-4 pr-2 montserrat uppercase font-semibold self-center sm:text-xl">Dashboard</p>
            <p class="sm:hidden pl-4 pr-2 mb-6">This is the dashboard where employees gain recent insights about productivity, wellbeing and receive daily tips. Integrations are possible, with your favorite tools such as Jira and Toggl.</p>
            <img class="w-full sm:w-1/2 filter-drop-shadow-soft sm:rotate-x--30deg sm:image-rotate-left" src="./assets/results/dashboard.png" alt="Dashboard" />
            <div class="hidden sm:block sm:w-1/2 self-center">
                <p class="montserrat uppercase font-semibold self-center text-center sm:text-xl">Dashboard</p>
                <p class="pl-4 pr-2 mb-6 lg:w-3/4 mx-auto">This is the dashboard where employees gain recent insights about productivity, wellbeing and receive daily tips. Integrations are possible, with your favorite tools such as Jira and Toggl.</p>
            </div>
        </div>
        <div class="sm:flex lg:-mt-20">
            <div class="w-full sm:w-1/2 self-center">
                <p class="mt-12 sm:mt-0 montserrat uppercase font-semibold self-center sm:text-xl text-center">Courses</p>
                <p class="pl-4 pr-2 mb-6 md:w-2/3 mx-auto">Courses and trainings are here to enhance your productivity or wellbeing. You can buy individual ones, or earn free courses by filling out the productivity and wellbeing survey</p>
            </div>
            <img class="w-full sm:w-1/2 filter-drop-shadow-soft sm:image-rotate-right" src="./assets/results/courses.png" alt="Courses" />
        </div>
        <div class="sm:flex lg:-mt-20">
            <p class="mt-12 sm:mt-0 sm:hidden montserrat uppercase font-semibold self-center text-center sm:text-xl">Daily Survey</p>
            <p class="sm:hidden pl-4 pr-2 mb-6">For the employee to fill this in every day. This gains insights about his productivity, wellbeing and the reason for this. With these insights you get personalized tips and courses.</p>
            <img class="w-full sm:w-1/2 filter-drop-shadow-soft sm:rotate-x--30deg sm:image-rotate-left" src="./assets/results/nps.png" alt="NPS-Survey" />
            <div class="hidden sm:block sm:w-1/2 self-center">
                <p class="montserrat uppercase font-semibold self-center text-center sm:text-xl">Daily survey</p>
                <p class="pl-4 pr-2 mb-6 lg:w-3/4 mx-auto">For the employee to fill this in every day. This gains insights about his productivity, wellbeing and the reason for this. With these insights you get personalized tips and courses.</p>
            </div>
        </div>
        <div class="sm:flex lg:-mt-20">
            <div class="w-full sm:w-1/2 self-center">
                <p class="mt-12 sm:mt-0 montserrat uppercase font-semibold self-center sm:text-xl text-center">Employer dashboard</p>
                <p class="pl-4 pr-2 mb-6 md:w-2/3 xl:w-3/4 mx-auto">This is where the employer gains insights about his team, checks at which location his employees work and use the tips and tools to manage his team. If there is something going on, he is always on top of it, thanks to the yourdesq dashboard.</p>
            </div>
            <img class="w-full sm:w-1/2 filter-drop-shadow-soft sm:image-rotate-right" src="./assets/results/employer-dashboard.png" alt="Employer dashboard" />
        </div>
    </div>

    <div class="bg-dark-blue text-white mb-4 mt-12 md:mb-8 md:mt-16 xl:mb-12 xl:mt-20">
        <div class="montserrat text-center font-semibold uppercase text-2xl py-8 xl:py-12 filter-drop-shadow">Conclusion</div>
    </div>
    <div class="pl-4 pr-2">
        <p class="w-full sm:w-2/3 lg:w-1/2 sm:mx-auto">We conclude that we created a platform that has a lot of ways to grow and could still be perfected as it is not perfect just yet. We created a good start for more employers to join YourDesq.</p>
    </div>


    <div class="my-20">
        <img class="mx-auto w-48" src="./assets/logo.svg" alt="Logo Digital Three">
    </div>
</template>

<script>
import Title from './components/Title';

export default {
    name: 'App',
    components: {
        Title,
    }
}
</script>

<style>
#app {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #111A49;
    margin-top: 60px;
}

.filter-drop-shadow {
    filter: drop-shadow(0 2px 3px rgba(0, 0, 0, .7));
}

.filter-drop-shadow-soft {
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, .5));
}

.hover\:filter-drop-shadow {
    transition: all .3s ease-in-out;
}

.hover\:filter-drop-shadow:hover {
    filter: drop-shadow(0 2px 3px rgba(0, 0, 0, .7));
    transition: all .3s ease-in-out;
}

.video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.text-orange {
    color: #F5885E;
}

.text-dark-blue {
    color: #111A49;
}

.montserrat {
    font-family: Montserrat, sans-serif;
}

.circular-std-black {
    font-family: "Circular Std", sans-serif;
    font-weight: 900;
}

.circular-std-bold {
    font-family: "Circular Std", sans-serif;
    font-weight: 600;
}

.circular-std-book {
    font-family: "Circular Std", sans-serif;
    font-weight: 400;
}

.circular-std-medium {
    font-family: "Circular Std", sans-serif;
    font-weight: 500;
}

.semi-bold {
    font-weight: 600;
}

li:before {
    content: "-";
    margin-right: 1rem;
    color:#F5885E;
}

@media (min-width: 640px) {
    .sm\:image-rotate-left {
        transform: rotateX(-30deg) rotateY(20deg) rotateZ(20deg) translateX(20px);
    }

    .sm\:image-rotate-right {
        transform: rotateX(-30deg) rotateY(20deg) rotateZ(20deg) translateX(-100px);
    }
}
</style>
