<template>
    <h1 class="text-dark-blue text-4xl">Your<span class="text-orange">desq</span> insights platform</h1>
</template>

<script>
export default {
    name: "Title"
}
</script>

<style scoped>
h1 {
    font-family: Montserrat, "Open Sans", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: 0 2px 2px rgba(0, 0, 0, .3);
}
</style>
